<style lang="scss" scoped>
    .container {
        background-color: #f8f8f8;
    }

    .form-inline {
        padding-top: 10px;
        background-color: #fff;
        padding-left: 16px;
        margin-bottom: 10px;
    }

    .pagination-container {
        text-align: right;
        margin-top: 10px;
    }

    .warning-color {
        color: #FF3B30;
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <recharge-products-search ref="rechargeProductsSearchRef" @update:handleAddBtn="handleAddBtn"></recharge-products-search>
                <recharge-products-table style="margin-top: 10px"
                                         @update:handleEditBtn="handleEditBtn"></recharge-products-table>

            </div>
        </el-main>
        <footer-pagination
                :pageInfo="pageInfo"
                @update:initData="search">
        </footer-pagination>
        <recharge-products-dialog
                ref="orderContactDialogRef"></recharge-products-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import rechargeProductsSearch from "./components/search/index"
    import rechargeProductsTable from "./components/table/index"
    import footerPagination from "@/www/components/footerPagination/index"

    import rechargeProductsDialog from "./components/dialog/index"
    export default {
        //定义模版数据
        data() {
            return {
                roleForm: {}
            }
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo
            },
            pageInfo() {
                return this.$store.state.rechargeProducts.page
            },
            isShow() {
                return this.$store.state.rechargeProducts.isShowDialog;
            },
        },
        components: {
            rechargeProductsSearch,
            rechargeProductsTable,
            footerPagination,
            rechargeProductsDialog,
        },
        //主件被加载完成
        mounted: function () {
            this.search(1);
        },
        //定义事件方法
        methods: {

            search(index) {
                const userType = parseInt(this.userInfo.userType);
                if(userType === 0){
                    this.$refs['rechargeProductsSearchRef'].search(index)
                }else {
                    this.$refs['rechargeProductsSearchRef'].searchRole(index)
                }

            },
            handleEditBtn(item) {
                this.$refs['orderContactDialogRef'].initData(item)
            },
            handleAddBtn(form) {
                this.$refs['orderContactDialogRef'].dialogRole(form)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>
