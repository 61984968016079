const columns = [
    {label: "产品ID", prop: "rechargeProductNo", width: '100'},
    {label: "运营商", prop: "operatorName"},
    {label: "供应商", prop: "supplierName"},
    {label: "服务类型", prop: "productTypeName", width: '120'},
    {label: "车型", prop: "carTypeName", width: '120'},
    {label: "包年价格/状态", prop: "yearlyPaymentPrice", width: '120', prop1: "yearlyStatus", showPrice: true},
    {label: "包季价格/状态", prop: "quarterlyPaymentPrice", width: '120', prop1: "quarterlyStatus", showPrice: true},
    {label: "包月价格/状态", prop: "monthlyPaymentPrice", width: '120', prop1: "monthlyStatus", showPrice: true},
    {label: "包日价格/状态", prop: "dayPaymentPrice", width: '120', prop1: "dayStatus", showPrice: true},
    {label: "单次接送价格/状态", prop: "oncePrice", width: '120', prop1: "oncePriceStatus", showPrice: true},
    {label: "单次按日包车价格/状态", prop: "oncePriceDay", width: '120', prop1: "oncePriceDayStatus", showPrice: true}
];
export default {
    columns
}
