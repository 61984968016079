<style lang="scss" scoped>
.order-contact-name /deep/ {
    display: flex;

    .el-form-item--small.el-form-item {
        margin-bottom: 0;
        height: 32px;
    }
}
</style>
<template>

    <div>
        <bms-dialog :isShow="dialogInfo.isShow"
                    :title="(dialogInfo.isAdd === 0 ? '新增':'编辑') + '车辆服务设置'"
                    isShowFooter
                    :width="420"
                    @update:cancelBtn="cancelBtn"
                    @update:submitBtn="submitBtn"
                    :submitState="dialogInfo.isAdd">
            <div slot="content">
                <el-form :model="form" :rules="rules" ref="form" size="small">
                    <el-form-item label="服务类型：" prop="productType" label-width="110px">
                        <el-radio-group v-model="form.productType">
                            <el-radio-button v-for="vm in rechargeProductTypes" :label="vm.label"
                                             :key="vm.label"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="运营商："
                                  prop="operatorId"
                                  label-width="110px"
                                  :rules="[{required: true, message: '请填写运营商', trigger: 'change'}]">
                        <operator-info-input
                            :selectOperatorId="form.operatorId"
                            :is-edit="userInfo.userType != 0"
                            ref="operatorInfoRef"
                            trigger
                            @update:getSelectOperator="getSelectOperator"
                            @update:selectOperatorBlur="selectOperatorBlur">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item label="供应商："
                                  prop="supplierId"
                                  label-width="110px"
                                  :rules="[{required: true, message: '请填写供应商', trigger: 'change'}]">
                        <supplier-info-input
                            :selectSupplierId="form.supplierId"
                            :is-edit="userInfo.userType == 1"
                            :operatorInfo="form.operatorInfo"
                            ref="supplierInfoRef"
                            trigger
                            @update:getSelectSupplier="getSelectSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="车型："
                                  prop="carType"
                                  label-width="110px"
                                  v-if="form.productType===rechargeProductTypes[1].label"
                                  :rules="[{required: true, message: '请选择车型', trigger: 'change'}]">
                        <seat-model v-model="form.carType"></seat-model>
                    </el-form-item>

                    <el-form-item label="包年服务价格："
                                  label-width="140px">
                        <div class="order-contact-name" style="height: 32px">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.yearlyStatus" style="">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.yearlyStatus === 1"
                                prop="yearlyPaymentPrice"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.yearlyPaymentPrice"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.yearlyPaymentPrice, 0)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="包季服务价格："
                                  label-width="140px">
                        <div class="order-contact-name">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.quarterlyStatus">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.quarterlyStatus === 1"
                                prop="quarterlyPaymentPrice"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.quarterlyPaymentPrice"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.quarterlyPaymentPrice, 1)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="包月服务价格："
                                  label-width="140px">
                        <div class="order-contact-name">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.monthlyStatus">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.monthlyStatus === 1"
                                prop="monthlyPaymentPrice"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.monthlyPaymentPrice"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.monthlyPaymentPrice, 2)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="包日服务价格："
                                  label-width="140px">
                        <div class="order-contact-name">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.dayStatus">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.dayStatus === 1"
                                prop="dayPaymentPrice"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.dayPaymentPrice"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.dayPaymentPrice, 5)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="单次接送服务："
                                  v-if="form.productType===rechargeProductTypes[1].label"
                                  label-width="140px">
                        <div class="order-contact-name">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.oncePriceStatus">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.oncePriceStatus === 1"
                                prop="oncePrice"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.oncePrice"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.oncePrice, 3)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item label="单次按日包车服务："
                                  v-if="form.productType===rechargeProductTypes[1].label"
                                  label-width="140px" >
                        <div class="order-contact-name">
                            <el-form-item style="width: 125px;" size="small">
                                <el-radio-group v-model="form.oncePriceDayStatus">
                                    <el-radio :label="0" style="margin-right: 10px">停用</el-radio>
                                    <el-radio :label="1">启用</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                v-if="form.oncePriceDayStatus === 1"
                                prop="oncePriceDay"
                                style="width:calc(100% - 125px)">
                                <el-input v-model="form.oncePriceDay"
                                          placeholder="请输入价格"
                                          @change="clearPrice(form.oncePriceDay, 4)"></el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </bms-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import bmsDialog from "@/www/components/dialog/index"
import operatorInfoInput from "@/www/components/operatorInfoInput/index"
import supplierInfoInput from "@/www/components/supplierInfoInput/index"
import {keepTwoDecimal} from '@/common/js/Utils';
import seatModel from "@/components/seatModel/index";
import {
    createRechargeProductInfo,
    updateRechargeProductInfo
} from "@/www/urls/rechargeProductsUrls"
import {findItem} from "@/common/js/common";
import {rechargeProductTypes} from "@/data";

export default {
    //定义模版数据
    data() {
        let checkYearlyPaymentPrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写包年价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };
        let checkQuarterlyPaymentPrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写包季价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };
        let checkMonthlyPaymentPrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写包月价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };
        let checkDayPaymentPrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写包日价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };
        let checkOncePrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写单次接送价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };
        let checkOncePriceDay = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('填写单次按日包车价格'));
            } else {
                if (value === '0.00') {
                    callback('价格必须大于0')
                } else {
                    callback()
                }
            }
        };


        return {
            rechargeProductTypes,
            form: {
                productType: rechargeProductTypes[0].label,//0代订车  1技术服务费
                operatorInfo: {},
                supplierInfo: {},
                operatorId: '',
                supplierId: '',
                carType: 1,
                yearlyPaymentPrice: '',
                yearlyStatus: 0,
                quarterlyPaymentPrice: '',
                quarterlyStatus: 0,
                monthlyPaymentPrice: '',
                monthlyStatus: 0,
                dayStatus: 0,
                dayPaymentPrice: '',
                oncePrice: '',
                oncePriceStatus: 0,
                oncePriceDay: '',
                oncePriceDayStatus: 0,
            },
            rules: {
                yearlyPaymentPrice: [{validator: checkYearlyPaymentPrice, trigger: 'blur'}],
                quarterlyPaymentPrice: [{validator: checkQuarterlyPaymentPrice, trigger: 'blur'}],
                monthlyPaymentPrice: [{validator: checkMonthlyPaymentPrice, trigger: 'blur'}],
                dayPaymentPrice: [{validator: checkDayPaymentPrice, trigger: 'blur'}],
                oncePrice: [{validator: checkOncePrice, trigger: 'blur'}],
                oncePriceDay: [{validator: checkOncePriceDay, trigger: 'blur'}],

            }
        }
    },
    props: {},
    components: {
        bmsDialog,
        operatorInfoInput,
        supplierInfoInput,
        seatModel,
    },
    //计算属性
    computed: {
        dialogInfo() {
            return this.$store.state.rechargeProducts.dialogInfo;
        },
        userInfo() {
            return this.$store.state.loginUserInfo
        },
    },
    //定义事件方法
    methods: {
        dialogRole(form) {
            const userType = parseInt(this.userInfo.userType);
            if (userType !== 0) {
                switch (userType) {
                    case 1:
                        this.form.supplierId = form.supplierId;
                        break;
                }
                this.form.operatorId = form.operatorId;
                this.form.operatorInfo = {
                    id: form.operatorId
                };
            }
        },
        clearPrice(value, type) {
            switch (type) {
                case 0:
                    this.form.yearlyPaymentPrice = keepTwoDecimal(value, 2);
                    break;
                case 1:
                    this.form.quarterlyPaymentPrice = keepTwoDecimal(value, 2);
                    break;
                case 2:
                    this.form.monthlyPaymentPrice = keepTwoDecimal(value, 2);
                    break;
                case 3:
                    this.form.oncePrice = keepTwoDecimal(value, 2);
                    break;
                case 4:
                    this.form.oncePriceDay = keepTwoDecimal(value, 2);
                    break;
                case 5:
                    this.form.dayPaymentPrice = keepTwoDecimal(value, 2);
                    break;
            }
        },
        initData(info) {
            if (info) {
                this.originalOperatorId = info.operatorId;
                this.form = {
                    ...info
                };
                this.form.productType = findItem(rechargeProductTypes, info.productType);
                this.form.operatorInfo = {
                    id: info.operatorId
                };
            }
        },
        getSelectOperator(item) {  // 运营商
            this.form.operatorInfo = item;
            this.form.operatorId = item.id;
            this.originalOperatorId = item.id;

        },
        selectOperatorBlur(value) {
            if (value !== this.originalOperatorId) {
                this.$refs.supplierInfoRef.clearSupplierName();
                this.form.supplierInfo = {};
                this.form.supplierId = '';
            }
        },
        getSelectSupplier(item) {
            this.form.supplierInfo = item;
            this.form.supplierId = item.id;
        },
        cancelBtn() {
            this.$refs.operatorInfoRef.clearOperatorName();
            this.$refs.supplierInfoRef.clearSupplierName();
            this.$refs.form.resetFields();
            this.form = {
                productType: rechargeProductTypes[0].label,
                operatorInfo: {},
                supplierInfo: {},
                operatorId: '',
                supplierId: '',
                carType: 1,
                yearlyPaymentPrice: '',
                yearlyStatus: 0,
                quarterlyPaymentPrice: '',
                quarterlyStatus: 0,
                monthlyPaymentPrice: '',
                monthlyStatus: 0,
                oncePrice: '',
                oncePriceStatus: 0,
                oncePriceDay: '',
                oncePriceDayStatus: 0,
            };
            let form = {
                isShow: false,
            };
            this.$store.dispatch('handle_dialog_info', form);
        },
        submitBtn() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let {
                            operatorId,
                            supplierId,
                            operatorInfo,
                            supplierInfo,
                            carType,
                            yearlyPaymentPrice,
                            yearlyStatus,
                            quarterlyPaymentPrice,
                            quarterlyStatus,
                            monthlyPaymentPrice,
                            monthlyStatus,
                            dayStatus,
                            dayPaymentPrice,
                            oncePrice,
                            oncePriceStatus,
                            oncePriceDay,
                            oncePriceDayStatus,
                            productType
                        } = this.form,
                        form = {
                            operatorId,
                            supplierId,
                            operatorName: operatorInfo.operatorName,
                            supplierName: supplierInfo.supplierName,
                            carType: productType === rechargeProductTypes[1].label ? carType : undefined,
                            yearlyPaymentPrice,
                            yearlyStatus,
                            quarterlyPaymentPrice,
                            quarterlyStatus,
                            monthlyPaymentPrice,
                            monthlyStatus,
                            dayStatus,
                            dayPaymentPrice,
                            oncePrice,
                            oncePriceStatus,
                            oncePriceDay,
                            oncePriceDayStatus,
                            productType: findItem(rechargeProductTypes, productType, 'value', 'label')
                        };
                    if (this.form.id) form.id = this.form.id;
                    let res = {};
                    let type = this.dialogInfo.isAdd;
                    switch (type) {
                        case 0:
                            res = await createRechargeProductInfo(form);
                            break;
                        case 1:
                            res = await updateRechargeProductInfo(form);
                            break
                    }
                    if (res.success && res.data) {
                        this.$message({
                            message: `${type === 0 ? '新增' : '编辑'}车辆服务产品成功`,
                            type: 'success'
                        });
                        this.cancelBtn();
                        this.$store.dispatch('search_recharge_product_list', {pageIndex: 1})
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

    },
    //监听模版变量
    watch: {}

}
</script>

