<style lang="scss" scoped>
.recharge-products-search /deep/ {
    .common-list-search {
        .list-search-before {
            width: calc(100% - 100px);

            .el-form-item.el-form-item--small {
                margin-bottom: 5px;
            }
        }

        .list-search-after {
            width: 100px;
        }
    }
}

</style>
<template>

    <div class="recharge-products-search">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item style="width: 200px">
                        <operator-info-input
                            :selectOperatorId="form.operatorId"
                            ref="operatorInfoRef"
                            :is-edit="userInfo.userType != 0"
                            @update:getSelectOperator="handleOperator"
                            clearable
                            :clearOperator="clearOperator">
                        </operator-info-input>
                    </el-form-item>
                    <el-form-item style="width: 200px">
                        <supplier-info-input
                            ref="supplierInfoRef"
                            :selectSupplierId="form.supplierId"
                            :operatorInfo="form.operatorInfo"
                            :is-edit="userInfo.userType == 1"
                            @update:getSelectSupplier="handleSupplier"
                            clearable
                            :clearSupplier="clearSupplier">
                        </supplier-info-input>
                    </el-form-item>
                    <el-form-item label="">
                        <seat-model :isAll="true" v-model="form.carType"></seat-model>
                    </el-form-item>

                    <el-form-item label="">
                        <el-input v-model="form.rechargeProductNo" placeholder="产品ID" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="">
                        <el-button @click="resetForm()">重置</el-button>
                        <el-button type="primary" @click="search(1)">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="list-search-after" style="text-align: right;">
                <el-button type="primary" @click="addBtn()">新增</el-button>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch/index.vue'
import operatorInfoInput from "@/www/components/operatorInfoInput/index"
import supplierInfoInput from "@/www/components/supplierInfoInput/index"
import {initForm} from '@/www/vuex/modules/rechargeProducts'
import seatModel from "@/components/seatModel/index";
import {getSupplierIdOperatorInfo} from '@/urls/index';

export default {
    //定义模版数据
    data () {
        return {
            form: {
                ...initForm
            },
        }
    },
    components: {
        comListSearch,
        operatorInfoInput,
        supplierInfoInput,
        seatModel,
    },
    //计算属性
    computed: {

        userInfo () {
            return this.$store.state.loginUserInfo
        },
    },
    //主件被加载完成
    mounted: function () {


    },
    //定义事件方法
    methods: {
        searchRole (index) {
            const userType = parseInt(this.userInfo.userType);
            if (userType !== 0) {
                this.getUserInfo(userType, index);
            }
        },
        getUserInfo (type, index) {
            switch (type) {
                case 1:
                    this.getOperatorInfo(this.userInfo.refId);
                    break;
                case 2:
                    this.form.operatorId = this.userInfo.refId;
                    break;
            }
            this.search(index)
        },
        async getOperatorInfo (id) {
            const ret = await getSupplierIdOperatorInfo(id);
            if (ret) {
                this.form.supplierId = this.userInfo.refId;
                this.form.operatorId = ret.operatorId;
                this.form.operatorInfo = {
                    id: this.form.operatorId
                };
            }
        },
        search (pageIndex) {
            let form = JSON.parse(JSON.stringify({...this.form}));
            if (form.carType === 0)
                delete form.carType;
            delete form.operatorInfo;
            delete form.supplierInfo;
            this.$store.dispatch('search_recharge_product_list', {pageIndex, form})
        },

        handleOperator (item) {
            this.form.operatorInfo = item;
            this.form.operatorId = item.id;
        },
        clearOperator () {
            this.form.operatorInfo = {};
            this.form.operatorId = '';
        },
        handleSupplier (item) {
            this.form.supplierInfo = item;
            this.form.supplierId = item.id;
        },
        clearSupplier () {
            this.form.supplierInfo = {};
            this.form.supplierId = '';
        },

        resetForm () {
            const userType = parseInt(this.userInfo.userType);
            let form = {};
            switch (userType) {
                case 0:
                    this.$refs.operatorInfoRef.clearOperatorName();
                    this.$refs.supplierInfoRef.clearSupplierName();
                    form = {...initForm};
                    break;
                case 1:
                    form = {
                        operatorId: this.form.operatorId,
                        supplierId: this.form.supplierId,
                        carType: 0,
                        rechargeProductNo: ''
                    };
                    break;
                case 2:
                    form = {
                        operatorId: this.form.operatorId,
                        supplierId: '',
                        carType: 0,
                        rechargeProductNo: ''
                    };
                    this.$refs.supplierInfoRef.clearSupplierName();
                    break;
            }
            this.form = {...form};
        },

        addBtn () {
            let data = {
                isShow: true,
                isAdd: 0,  // 0 新增  1 编辑
            };
            this.$store.dispatch('handle_dialog_info', data);
            this.$emit("update:handleAddBtn", this.form)
        },

    },
    //监听模版变量
    watch: {}

}
</script>

