<style lang="scss" scoped>
.color-grey {
    color: #c0c4cc;
}

</style>
<template>

    <div>
        <recharge-products-table :columns="data.columns" :dataList="list" :show-buttons="true" :is-index="true"
                                 :is-slot="true">
            <template v-slot:desc="slotDesc">
                <p v-if="slotDesc.col.showPrice">
                    <span v-if="slotDesc.row[slotDesc.col.prop]!=='无'">
                        {{ slotDesc.row[slotDesc.col.prop] | currency }}
                        <span style="margin-left: 10px" :class="{'color-grey':slotDesc.row[slotDesc.col.prop1] === 0}">
                        {{ slotDesc.row[slotDesc.col.prop1] === 0 ? '失效' : '有效' }}
                    </span>
                    </span>
                </p>
                <p v-else>
                    {{ slotDesc.row[slotDesc.col.prop] }}
                </p>
            </template>
            <template v-slot:buttons="slotRow">
                <el-button type="text" @click="handleEditBtn(slotRow.row)">编辑</el-button>
                <el-button type="text" @click="handleDelBtn(slotRow.row)" style="color: #f56c6c">删除</el-button>
                <!--                <el-popover-->
                <!--                        v-if="userInfo.userType != 1"-->
                <!--                        placement="top"-->
                <!--                        width="120"-->
                <!--                        trigger="click"-->
                <!--                        v-model="slotRow.row.showPopup">-->
                <!--                    <p>确认删除</p>-->
                <!--                    <div style="text-align: right; margin: 0">-->
                <!--                        <el-button size="mini" type="text" @click="slotRow.row.showPopup = false">取消</el-button>-->
                <!--                        <el-button type="primary" size="mini" @click="handleDelBtn(slotRow.row)">确定</el-button>-->
                <!--                    </div>-->
                <!--                    <el-button slot="reference" type="text" style="color: #f56c6c">删除</el-button>-->
                <!--                </el-popover>-->
            </template>
        </recharge-products-table>
    </div>

</template>

<script type="text/ecmascript-6">
import data from "./data"
import rechargeProductsTable from "@/www/components/table/index"
import {
    rechargeProductInfoDelete
} from "@/www/urls/rechargeProductsUrls"

export default {
    //定义模版数据
    data() {
        return {
            data
        }
    },
    components: {
        rechargeProductsTable
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        list() {
            return this.$store.state.rechargeProducts.list
        },
    },
    //主件被加载完成
    mounted: function () {

    },
    //定义事件方法
    methods: {
        handleEditBtn(item) {
            this.$emit("update:handleEditBtn", item);
            let form = {
                isShow: true,
                isAdd: 1,  // 0 新增  1 编辑
            };
            this.$store.dispatch('handle_dialog_info', form);
        },
        async handleDelBtn(item) {
            try {
                await this.$confirm('确定删除当前产品, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                const res = await rechargeProductInfoDelete(item.id);
                let pageIndex = 1,
                    form = {};
                if (res.success && res.data) {
                    this.$store.dispatch('search_recharge_product_list', {pageIndex, form});
                    this.loadingHide();
                    this.$message.success('删除成功！');
                } else {
                    this.$message.error(res.errors[0].message)
                }
            } catch (e) {
                console.log(e);
            }
        }


    },
    //监听模版变量
    watch: {}

}
</script>

